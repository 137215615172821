import React from "react";
import { graphql } from "gatsby";
const Package = (props) => {
  const { frontmatter } = props.data.markdownRemark;

  return (
    <div className="max-w-4xl mx-auto flex flex-col pt-10 pb-20">
      <div className="w-full text-4xl md:text-5xl uppercase flex justify-center md:block font-bold md:pl-16">
        {frontmatter.title}:
      </div>
      <div className="w-full flex flex-wrap pt-10">
        <div className="w-full md:w-1/2 pr-10 md:pr-0">
          <img
            src={frontmatter.package_img.childImageSharp.fluid.src}
            alt={frontmatter.alt}
          ></img>
        </div>
        <div className="w-full md:w-1/2 flex justify-center">
          <ul className="w-full md:pl-10">
            {frontmatter.features.map((feature, index) => {
              return (
                <li key={index} className="robotoFont font-bold">
                  - {feature}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};
export const query = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        features
        alt
        package_img {
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
export default Package;
